import React from "react";
// Customizable Area Start
import {
    Avatar,
    Box,
    Button,
    ClickAwayListener,
    Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, MenuItem, TextField, Typography
} from "@material-ui/core";
import { Theme, createStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { CustomizedTextField } from "../../dashboard/src/SinglePropertiesDetails.web";
import { expandLess, expandMore } from "./assets";
import { AdditionalServicesforPets, ReservationsAttributes, Roomtypeforcat, Additionalservicesforcat } from "./types";
import { boxShadows, colors, theme } from "../../../components/src/theme";
import { calenderIcon } from "../../user-profile-basic/src/assets";
import { errorImage } from "../../../components/src/CommonFunctions";
import ReservationDailogController, { Props, configJSON } from "./ReservationDailogsController.web";
import moment from "moment";
import { Calendar } from "react-multi-date-picker";
import { crossIcon } from "../../landingpage/src/assets";
import { arrowDown } from "../../shoppingcart/src/assets";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
// Customizable Area End
// Customizable Area Start
// Customizable Area End

export class ReservationDailog extends ReservationDailogController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderRoomTypes = () => {
        const { classes } = this.props
        return (
            <>
                <ClickAwayListener data-test-id="close-modal" onClickAway={this.props.closeRoomTypes} >
                    <Box className={classes.claenderLayout} style={{ left: '0px' }} >
                        <Box className={classes.outer}>
                            {this.props.roomListBo.map((room) => {
                                return (
                                    <MenuItem
                                        className={classes.dropDownTextRoomType}
                                        data-test-id='select_room_type'
                                        value={this.state.selectedRoomType}
                                        key={room.id} onClick={() => this.props.selectRoomType(room.room_type)}>{room.room_type}</MenuItem>
                                )

                            })}
                        </Box>
                    </Box>
                </ClickAwayListener>
            </>
        );
    }

    calender = () => {
        const { classes } = this.props
        return (
            <div style={{ overflow: "hidden" }}>
                <Calendar
                    onChange={this.props.handleDateChange}
                    data-test-id="select_calender"
                    range
                    numberOfMonths={2}
                    value={this.props.selectedRange}
                    className={classes.customCalender}
                >

                </Calendar>
            </div>
        )
    }
    renderReviewsCalender = () => {
        const { classes } = this.props
        return (
            <>
                <ClickAwayListener onClickAway={this.props.closeSelectDate} >
                    <Box className={classes.claenderLayout} >
                        <Box className={classes.outer}>
                            <Grid item xs={12}>
                                <Grid container direction="row" spacing={3}>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" justifyContent="space-between">
                                            <Grid item>
                                                <Typography variant="body1" className={classes.dailogHeading}>
                                                    {'Select dates'}
                                                </Typography>

                                            </Grid>
                                            <Grid item data-test-id="close_calender" onClick={this.props.closeDate}>
                                                <img src={crossIcon} />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} justifyContent="flex-end" alignItems="center">
                                            <Typography align="right" className={classes.selectedTabText}
                                                color="secondary" variant="body1"
                                                onClick={this.props.clearDates}
                                                data-test-id='clear_dates'
                                            >
                                                {"Clear dates"}</Typography>

                                        </Grid>

                                    </Grid>

                                </Grid>

                            </Grid>
                            {this.calender()}
                        </Box>
                    </Box>
                </ClickAwayListener>
            </>
        );
    }

    renderDailogButtons = (dailogType: string) => {
        const { classes }: any = this.props;


        return (
            <>
                {dailogType === 'reject' && <Button variant="outlined"
                    onClick={this.rejectAction}
                    data-test-id='rejectAction'
                    color="secondary" className={classes.rejectBtn} >
                    Reject now
                </Button>}
                {dailogType === 'settingsFilters' && (
                    <>
                        <Button
                            variant="contained"
                            onClick={this.props.handleClear}
                            data-test-id='cancel'
                            color="secondary"
                            className={classes.searchBtn}
                            style={{
                                backgroundColor: "white",
                                color: theme.palette.secondary.main
                            }}
                        >
                            Clear
                        </Button>
                        <Button variant="contained"
                            onClick={this.handleSearch}
                            data-test-id='search'
                            color="secondary" className={classes.searchBtn} >
                            Search
                        </Button>
                    </>
                )}
            </>
        )
    }

    renderCheckInPopUp = () => {
        const { classes, popUpBtnText } = this.props;
        const dogs = this.props.reservationDetails.attributes.number_of_dogs;
        const cats = this.props.reservationDetails.attributes.number_of_cats;
        let popUpName = popUpBtnText === 'Check-Out' ? 'Check Out' : popUpBtnText === 'Check-In' ? 'Check In' : popUpBtnText
        return (
            <>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} className={classes.primaryBox}>
                            <Grid container spacing={2} style={{ padding: this.props.dailogType === 'checkin' ? "20px 100px 20px 100px" : "" }}>
                                <Grid item xs={12}>
                                    <Typography variant="h4" className={classes.dailogHeading}>{popUpName}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ marginBottom: 15 }} className={classes.dailogHeading}>Guest Room Info</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={5} className={classes.imageFocus}>
                                            <img src={this.props.reservationDetails?.attributes?.images[0]?.url} onError={errorImage} className={classes.HotelImage} />
                                        </Grid>
                                        <Grid item xs={12} sm={7} style={webStyle.layoutPadding}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} style={{ padding: "0px 4px" }}>
                                                    <Typography variant="h6" className={classes.dailogHeading}>{this.props.reservationDetails.attributes.hotel_information.name}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={1} alignItems="center" style={{ marginBottom: "10px" }}>
                                                        <Grid item >
                                                            <LocationOnOutlinedIcon />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="body1" className={classes.dailogSubHeading}>{this.props.reservationDetails.attributes.hotel_information.hotel_city},&nbsp;
                                                                {this.props.reservationDetails.attributes.hotel_information.hotel_country_region}

                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                                <Grid item xs={12} className={classes.tableSection}>
                                                    <Grid container spacing={1} className={classes.hotelDetails}>
                                                        {this.renderClaculation()}
                                                    </Grid>
                                                </Grid>

                                            </Grid>

                                        </Grid>


                                    </Grid>

                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} style={{ padding: this.props.dailogType === 'checkin' ? "20px 115px 20px 115px" : "" }}>
                                <Grid item xs={12} sm={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.dailogHeading}>Check in</Typography>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box className={classes.inputDropDownBox} >
                                                <Box className={classes.inputDropDownBoxContent}>
                                                    {this.props.reservationDetails.attributes.check_in_date &&
                                                        <Typography variant="body1">
                                                            {moment(this.props.reservationDetails.attributes.check_in_date).format("DD MMMM YYYY")}
                                                        </Typography>
                                                    }

                                                    <img src={calenderIcon} />
                                                </Box>
                                            </Box>


                                        </Grid>

                                    </Grid>

                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.dailogHeading}>Check out</Typography>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box className={classes.inputDropDownBox} >
                                                <Box className={classes.inputDropDownBoxContent}>
                                                    {this.props.reservationDetails.attributes.check_out_date &&
                                                        <Typography variant="body1">
                                                            {moment(this.props.reservationDetails.attributes.check_out_date).format("DD MMMM YYYY")}

                                                        </Typography>
                                                    }

                                                    <img src={calenderIcon} />
                                                </Box>
                                            </Box>
                                        </Grid>

                                    </Grid>

                                </Grid>
                                {this.props.reservationDetails.attributes.hotel_information.pet_type === "daycare_services" &&
                                    <>
                                        {this.props.reservationDetails.attributes.day_care_service_type === "Repeat_Weekly" && <Grid item xs={12}>
                                            <Grid container direction="row" spacing={2} alignItems="center">
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" className={classes.dailogHeading}>{configJSON.selectedDates}</Typography>

                                                </Grid>
                                                {this.props.reservationDetails.attributes.blocked_days && this.props.reservationDetails.attributes.blocked_days.map((days: string) => {
                                                    return (<Grid item xs={12} sm={6}>
                                                        <Typography variant="body1"> {this.getDayOfWeek(days)}, {moment(days).format("DD MMMM YYYY")}</Typography>

                                                    </Grid>)
                                                })}

                                            </Grid>

                                        </Grid>}
                                    </>}
                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.dailogHeading}>Number of  Pets</Typography>

                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box className={classes.inputDropDownBox} >
                                                <Box className={classes.inputDropDownBoxContent}>

                                                    <Typography variant="body1">
                                                        {this.getDogCount(dogs)} &nbsp;{dogs > 0 && cats > 0 && "and"}&nbsp;
                                                        {this.getCatCount(cats)}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>

                                    </Grid>

                                </Grid>
                                {this.props.reservationDetails.attributes?.for_cat?.room_type_for_cat.length > 0 &&
                                    <>
                                        {this.renderSelectTypes('cats')}
                                    </>}
                                {this.props.reservationDetails.attributes.for_dog?.room_type_for_dog.length > 0 &&
                                    <>
                                        {this.renderSelectTypes('dogs')}
                                    </>}
                                <Grid item xs={12} style={{ marginTop: 32 }}>
                                    <Divider className={classes.verticalDivider} />

                                </Grid>

                                {this.getQuestions() && <Box className={classes.additionalQuestionsBox}>
                                    <Typography className={classes.additionalQuestionsText}>{configJSON.additionalQuestions}</Typography>
                                    <Box className={classes.questionBox}>
                                        <Typography className={classes.questionText}>{this.props.reservationDetails?.attributes.additional_question_and_answer?.additional_question1?.additional_question_one}</Typography>
                                        <Typography className={classes.answerText}>{this.props.reservationDetails?.attributes.additional_question_and_answer?.additional_question1?.additional_question_answer_one}</Typography>
                                    </Box>
                                    <Box className={classes.questionBox}>
                                        <Typography className={classes.questionText}>{this.props.reservationDetails?.attributes.additional_question_and_answer?.additional_question2?.additional_question_two}</Typography>
                                        <Typography className={classes.answerText}>{this.props.reservationDetails?.attributes.additional_question_and_answer?.additional_question2?.additional_question_answer_two}</Typography>
                                    </Box>
                                    <Box className={classes.questionBox}>
                                        <Typography className={classes.questionText}>{this.props.reservationDetails?.attributes.additional_question_and_answer?.additional_question3?.additional_question_three}</Typography>
                                        <Typography className={classes.answerText}>{this.props.reservationDetails?.attributes.additional_question_and_answer?.additional_question3?.additional_question_answer_three}</Typography>
                                    </Box>
                                </Box>}

                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={2} style={{ marginTop: 32 }}>
                                        <Grid item xs={12} sm={6}>
                                            <Button variant="outlined"
                                                fullWidth
                                                onClick={this.closeCommonDailog}
                                                data-test-id='clos_commonDailog'
                                                color="secondary" className={classes.rejectBtn} >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Button variant="contained"
                                                onClick={this.checkinRes}
                                                fullWidth
                                                data-test-id='checkedin_btn'
                                                color="secondary" className={classes.checkBtn} >
                                                {popUpName}
                                            </Button>
                                        </Grid>

                                    </Grid>

                                </Grid>

                            </Grid>

                        </Grid>



                    </Grid>

                </Grid>

            </>
        )
    }

    renderSelectTypes = (type: string) => {
        const { classes } = this.props;
        const dataServices: ReservationsAttributes = this.props.reservationDetails.attributes;



        return (
            <>
                <Grid item xs={12} style={{ margin: "10px 0" }}>
                    <Box display={'flex'} alignItems="center">
                        <Box width='20%' >
                            <Typography variant="body1" className={classes.dailogSubHeading}>Select for {type}</Typography>
                        </Box>
                        <Box width={'100%'}>
                            <Divider />
                        </Box>

                    </Box>

                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="body1" className={classes.dailogHeading}>Select the room type for {type}</Typography>

                        </Grid>
                        <Grid item xs={12}>
                            <Box className={classes.inputDropDownBox} >
                                <Box className={classes.inputDropDownBoxContent}>
                                    {type === 'cats' &&
                                        dataServices?.for_cat.room_type_for_cat?.map((values: Roomtypeforcat) => {
                                            return (
                                                <>
                                                    <Typography variant="body1">{values.room_type}</Typography>
                                                </>
                                            )
                                        })}
                                    {type === 'dogs' &&
                                        dataServices.for_dog?.room_type_for_dog?.map((values: Roomtypeforcat) => {
                                            return (
                                                <>
                                                    <Typography variant="body1">{values.room_type}</Typography>
                                                </>
                                            )
                                        })}

                                </Box>
                            </Box>


                        </Grid>
                        <Grid item xs={12}>
                            <div
                                style={{
                                    ...webStyle.additionalselect,
                                    position: "relative", alignItems: "baseline"
                                } as React.CSSProperties}
                            >
                                <Box display="flex" flexDirection="column" width="100%">
                                    <Typography variant="subtitle2" className={classes.dailogSubHeading}>Add additional services</Typography>
                                    <Box style={{ display: "flex", flexWrap: "wrap" }}>

                                        {type === 'cats' &&
                                            dataServices?.for_cat.additional_services_for_cat?.map((values) => {
                                                return (
                                                    <>
                                                        <Typography variant="body1" className={classes.dailogSubHeading}>{values.name}</Typography>

                                                    </>
                                                )
                                            })}
                                        {type === 'dogs' && dataServices?.for_dog.additional_services_for_dog?.map((values) => {
                                            return (
                                                <>
                                                    <Typography variant="body1" className={classes.dailogSubHeading}>{values.name}</Typography>


                                                </>
                                            )
                                        })}
                                    </Box>
                                </Box>
                            </div>

                        </Grid>

                    </Grid>

                </Grid>
            </>
        )
    }

    renderPetDetails = () => {
        const { emergencycontactDropDownStatus } = this.state

        const { classes, petDetails } = this.props;

        return (
            <>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={6} justifyContent="flex-start">
                                <Grid item xs={2} sm={3}>
                                    <Avatar src={petDetails.pet_photo} style={{ width: '153px', height: '153px', border: `3px solid ${theme.palette.primary.main}` }} />
                                </Grid>
                                <Grid item xs={10} sm={9}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h4" className={classes.dailogHeading}>{petDetails.name}
                                                <Typography display="inline" variant="h4" className={classes.heading} style={{ textTransform: 'capitalize' }}>
                                                    ({petDetails.pet_type})
                                                </Typography>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" spacing={1}>
                                                <Grid item>
                                                    <Typography variant="body1" className={classes.heading}>Breed:
                                                        <Typography display="inline" variant="body1" className={classes.dailogHeading}>
                                                            {petDetails.breed}
                                                        </Typography>
                                                    </Typography>

                                                </Grid>
                                                <Grid item>
                                                    <Divider orientation="vertical" className={classes.verticalDivider} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1" className={classes.heading}>Sex:
                                                        <Typography display="inline" variant="body1" className={classes.dailogHeading}>
                                                            {petDetails.gender}
                                                        </Typography>
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Divider orientation="vertical" className={classes.verticalDivider} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1" className={classes.heading}>Date of Birth:
                                                        {petDetails.date_of_birth && <Typography display="inline" variant="body1" className={classes.dailogHeading}>
                                                            {moment(petDetails.date_of_birth).format('DD/MM/YYYY')}
                                                        </Typography>}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Divider orientation="vertical" className={classes.verticalDivider} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1" className={classes.heading}>Colour:
                                                        <Typography display="inline" variant="body1" className={classes.dailogHeading}>
                                                            {petDetails.colour}
                                                        </Typography>
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Divider orientation="vertical" className={classes.verticalDivider} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1" className={classes.heading}>Weight:
                                                        <Typography display="inline" variant="body1" className={classes.dailogHeading}>
                                                            {petDetails.weight}&nbsp;Kg.
                                                        </Typography>
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Divider orientation="vertical" className={classes.verticalDivider} />
                                                </Grid>
                                                <Grid item>
                                                    {petDetails.spayed === "ChemicallyCastrated" ? (
                                                        <Typography variant="body1" className={classes.heading}>Spayed / Neutered:
                                                            <Typography display="inline" variant="body1" className={classes.dailogHeading} data-test-id="spayed">
                                                                Chemically Castrated Last procedure date:{moment(petDetails.last_procedure_date).format('DD/MM/YYYY')}
                                                            </Typography>
                                                        </Typography>
                                                    ) :
                                                        <>
                                                            <Typography variant="body1" className={classes.heading}>Spayed / Neutered:
                                                                <Typography display="inline" variant="body1" className={classes.dailogHeading}>
                                                                    {petDetails.pet_type === "dog" && petDetails.spayed}
                                                                    {petDetails.pet_type === "cat" && (petDetails.spayed === "Spayed" ? "Yes" : "No")}
                                                                </Typography>
                                                            </Typography>
                                                        </>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <div className={classes.dividerTextLayout}>
                                        <div style={{ width: "83%" }}>

                                            <Typography variant="h6" className={emergencycontactDropDownStatus ? classes.heading : classes.disbaledText}>
                                                Emergency contact details
                                            </Typography>
                                        </div>
                                        <div style={{ width: '100%', margin: '0 10px' }}>
                                            <Divider className={emergencycontactDropDownStatus ? classes.enableDivider : classes.disabledDivider} />

                                        </div>
                                        <div data-test-id='contact' onClick={this.emergencyContactDropDown}>
                                            <img
                                                className={classes.cursor}
                                                src={emergencycontactDropDownStatus ? expandLess : expandMore} />
                                        </div>
                                    </div>

                                    {emergencycontactDropDownStatus &&
                                        <Grid item xs={12} sm={10} style={{ margin: "20px 0" }}>
                                            <Grid container direction="row" spacing={1}>
                                                <Grid item>
                                                    <Typography variant="body1" className={classes.heading}>Contact name:
                                                        <Typography display="inline" variant="body1" className={classes.dailogHeading}>
                                                            {petDetails.emergency_contact_name}
                                                        </Typography>
                                                    </Typography>

                                                </Grid>
                                                <Grid item>
                                                    <Divider orientation="vertical" className={classes.verticalDivider} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1" className={classes.heading}>Contact email:
                                                        <Typography display="inline" variant="body1" className={classes.dailogHeading}>
                                                            {petDetails.emergency_contact_email}

                                                        </Typography>
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Divider orientation="vertical" className={classes.verticalDivider} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1" className={classes.heading}>Contact number:
                                                        <Typography display="inline" variant="body1" className={classes.dailogHeading}
                                                            style={{ textTransform: 'capitalize' }}
                                                        >
                                                            {petDetails.emergency_country_code}&nbsp;{petDetails.emergency_contact_number}

                                                        </Typography>
                                                    </Typography>
                                                </Grid>


                                            </Grid>


                                        </Grid>}
                                </Grid>
                                {this.renderLegalDetails()}
                                {this.renderDetails()}


                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

            </>

        )
    }
    renderLegalDetails = () => {
        const { legalContactDropDownStatus } = this.state
        const { classes, petDetails } = this.props;
        return (
            <>

                <Grid item xs={12}>
                    <div className={classes.dividerTextLayout}>
                        <div style={{ width: "72%" }}>

                            <Typography variant="h6" className={legalContactDropDownStatus ? classes.heading : classes.disbaledText}>
                                Legal Guardian contacts
                            </Typography>
                        </div>
                        <div style={{ width: '100%', margin: '0 10px' }}>
                            <Divider className={legalContactDropDownStatus ? classes.enableDivider : classes.disabledDivider} />

                        </div>
                        <div data-test-id="legal" onClick={this.legalContactDropDown}>
                            <img
                                className={classes.cursor}
                                src={legalContactDropDownStatus ? expandLess : expandMore} />
                        </div>
                    </div>
                    {legalContactDropDownStatus &&
                        <>
                            {petDetails.legal_guardian_details_status ? <Grid item xs={12} style={{ margin: "20px 0" }}>
                                <Typography variant="body1" className={classes.dailogHeading}>
                                    The legal guardian the same person as the emergency contact
                                </Typography>

                            </Grid>
                                :
                                <Grid item xs={12} sm={10} style={{ margin: "20px 0" }}>
                                    <Grid container direction="row" spacing={1}>
                                        <Grid item>
                                            <Typography variant="body1" className={classes.heading}>Contact name:
                                                <Typography display="inline" variant="body1" className={classes.dailogHeading}>
                                                    {petDetails.guardian_name}
                                                </Typography>
                                            </Typography>

                                        </Grid>
                                        <Grid item>
                                            <Divider orientation="vertical" className={classes.verticalDivider} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body1" className={classes.heading}>Contact email:
                                                <Typography display="inline" variant="body1" className={classes.dailogHeading}>
                                                    {petDetails.guardian_email}

                                                </Typography>
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Divider orientation="vertical" className={classes.verticalDivider} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body1" className={classes.heading}>Contact number:
                                                <Typography display="inline" variant="body1" className={classes.dailogHeading}
                                                    style={{ textTransform: 'capitalize' }}
                                                >
                                                    {petDetails.guardian_country_code}&nbsp;{petDetails.guardian_contact_number}
                                                </Typography>
                                            </Typography>
                                            us                               </Grid>


                                    </Grid>


                                </Grid>
                            }
                        </>
                    }

                </Grid>
            </>
        )
    }

    renderRooms = () => {
        const { classes } = this.props;
        const { rooms } = this.state;

        return rooms.map((room: { id: number, roomCount: any }) => (
            <Grid item className={classes.countBox}
                style={{
                    backgroundColor: (this.props.selectedRoomCount === room.roomCount || (this.props.selectedRoomCount === 0 && room.roomCount === 'Any')) ? colors.primaryBlue : 'white',
                    color: (this.props.selectedRoomCount === room.roomCount || (this.props.selectedRoomCount === 0 && room.roomCount === 'Any')) ? 'white' : colors.primaryBlue
                }}
                key={room.id}>
                <Button
                    data-test-id="room_select"
                    onClick={() => this.props.handleRoomSelect(room.roomCount)}
                >
                    <Typography>{room.roomCount === 0 ? 'Any' : room.roomCount}</Typography>
                </Button>
            </Grid>
        ));
    };

    renderDetails = () => {
        const { vetClinicDropDownStatus, medicalNotesDropDownStatus } = this.state

        const { classes, petDetails } = this.props;
        return (
            <>
                <Grid item xs={12}>
                    <div className={classes.dividerTextLayout}>
                        <div style={{ width: '38%' }}>

                            <Typography variant="h6" className={medicalNotesDropDownStatus ? classes.heading : classes.disbaledText}>
                                Medical notes
                            </Typography>
                        </div>
                        <div style={{ width: '100%', margin: "0 10px" }}>
                            <Divider className={medicalNotesDropDownStatus ? classes.enableDivider : classes.disabledDivider} />
                        </div>
                        <div data-test-id='medical' onClick={this.medicalDropDown}>
                            <img
                                className={classes.cursor}
                                src={medicalNotesDropDownStatus ? expandLess : expandMore} />
                        </div>
                    </div>
                    {medicalNotesDropDownStatus &&
                        <Grid item xs={12}>
                            <Grid container direction="row" spacing={2} style={{ margin: "20px 0" }}>

                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.dailogHeading}>
                                                Medical treatment:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>

                                            {petDetails.medical_treatment ? <TextField
                                                value={petDetails.medical_treatment}
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                minRows={2}
                                                InputProps={{ readOnly: true }}

                                            /> :
                                                <Grid item container direction="row">
                                                    <Grid item xs={12}>
                                                        <Typography style={webStyle.noText} align="center" variant="body1" className={classes.dailogHeading}>{configJSON.noMedicalTreatment}</Typography>
                                                    </Grid>
                                                </Grid>}


                                        </Grid>
                                        <Grid item xs={12} sm={10} style={{ margin: "20px 0" }}>
                                            <Grid container direction="row" spacing={1}>
                                                {petDetails.pet_type === 'cat' && <Grid item>
                                                    <Typography variant="body1" className={classes.heading}>Vaccination date:
                                                        <Typography display="inline" variant="body1" className={classes.dailogHeading}>
                                                            {petDetails.vaccination_date && <>{moment(petDetails.vaccination_date).format('DD/MM/YYYY')}</>}
                                                        </Typography>
                                                    </Typography>

                                                </Grid>}
                                                {this.renderDogVaccinations()}
                                                <Grid item>
                                                    <Divider orientation="vertical" className={classes.verticalDivider} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1" className={classes.heading}>Last flea treatment date:
                                                        <Typography display="inline" variant="body1" className={classes.dailogHeading} data-test-id="flea_date">
                                                            {petDetails.last_flea_treatment_date ? <>{moment(petDetails.last_flea_treatment_date).format('DD/MM/YYYY')}</> : configJSON.noneText}
                                                        </Typography>
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Divider orientation="vertical" className={classes.verticalDivider} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1" className={classes.heading}>Last worming date:
                                                        <Typography display="inline" variant="body1" className={classes.dailogHeading} data-test-id="worming_date">
                                                            {petDetails.last_worming_date ? <>{moment(petDetails.last_worming_date).format('DD/MM/YYYY')}</> : configJSON.noneText}
                                                        </Typography>
                                                    </Typography>

                                                </Grid>
                                                <Grid item>
                                                    <Divider orientation="vertical" className={classes.verticalDivider} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1" className={classes.heading}>Vaccination Certificate:
                                                        <Typography component={'a'} target="_blank" href={petDetails.vaccination_certificate.vaccination_certificate} display="inline" variant="body1" className={classes.dailogHeading}>
                                                            {petDetails.vaccination_certificate.filename}

                                                        </Typography>
                                                    </Typography>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.dailogHeading}>
                                                Dietary requirements:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {petDetails.dietary_requirements ? <TextField
                                                value={petDetails.dietary_requirements}
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                minRows={2}
                                                InputProps={{ readOnly: true }}
                                            /> :
                                                <Grid item container direction="row">
                                                    <Grid item xs={12}>
                                                        <Typography style={webStyle.noText} align="center" variant="body1" className={classes.dailogHeading}
                                                            data-test-id="noDietary"
                                                        >{configJSON.noDietary}</Typography>
                                                    </Grid>
                                                </Grid>}

                                        </Grid>
                                    </Grid>

                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={1}>
                                        <Grid item xs={12}>

                                            <Typography variant="body1" className={classes.dailogHeading}>
                                                Behavioral notes:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {petDetails.behavioral_notes ? <TextField
                                                value={petDetails.behavioral_notes}
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                minRows={2}
                                                InputProps={{ readOnly: true }}
                                            /> :
                                                <Grid item container direction="row">
                                                    <Grid item xs={12}>
                                                        <Typography align="center" style={webStyle.noText} variant="body1" className={classes.dailogHeading}
                                                            data-test-id="noBehavior"
                                                        >{configJSON.noBehavior}</Typography>
                                                    </Grid>
                                                </Grid>

                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>


                            </Grid>
                        </Grid>}
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.dividerTextLayout}>
                        <div style={{ width: "52%" }}>

                            <Typography variant="h6" className={vetClinicDropDownStatus ? classes.heading : classes.disbaledText}>
                                Vet Clinic and Insurance details
                            </Typography>
                        </div>
                        <div style={{ width: '47%', margin: "0 10px" }}>
                            <Divider className={vetClinicDropDownStatus ? classes.enableDivider : classes.disabledDivider} />

                        </div>
                        <div data-test-id='vetClinic' onClick={this.vetClinicDropDown}>
                            <img
                                className={classes.cursor}
                                src={vetClinicDropDownStatus ? expandLess : expandMore} />
                        </div>
                    </div>

                    {vetClinicDropDownStatus && <Grid item xs={12} style={{ margin: "20px 0" }}>
                        <Grid container direction="row" spacing={1}>
                            <Grid item>
                                <Typography variant="body1" className={classes.heading}>Clinic name:
                                    <Typography display="inline" variant="body1" className={classes.dailogHeading}>
                                        {petDetails.vet_name}
                                    </Typography>
                                </Typography>

                            </Grid>
                            <Grid item>
                                <Divider orientation="vertical" className={classes.verticalDivider} />
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" className={classes.heading}>Clinic number:
                                    <Typography display="inline" variant="body1" className={classes.dailogHeading}>
                                        {petDetails.vat_clinic_phone_country_code} {petDetails.vet_phone_number}
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Divider orientation="vertical" className={classes.verticalDivider} />
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" className={classes.heading}>Clinic address:
                                    <Typography display="inline" variant="body1" className={classes.dailogHeading}>
                                        {petDetails.vet_address}
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Divider orientation="vertical" className={classes.verticalDivider} />
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" className={classes.heading} data-test-id="insurance_label">Insurance provider:
                                    <Typography display="inline" variant="body1" className={classes.dailogHeading} data-test-id="insurance_mock_value">
                                        {petDetails.insurance_provider ? petDetails.insurance_provider : configJSON.noneText}
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Divider orientation="vertical" className={classes.verticalDivider} />
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" className={classes.heading}>Policy number:
                                    <Typography display="inline" variant="body1" className={classes.dailogHeading}>
                                        {petDetails.policy_number ? petDetails.policy_number : configJSON.noneText}
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Divider orientation="vertical" className={classes.verticalDivider} />
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" className={classes.heading}>Microchip number:
                                    <Typography display="inline" variant="body1" className={classes.dailogHeading}>
                                        {petDetails.microchip_number ? petDetails.microchip_number : configJSON.noneText}
                                    </Typography>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>}
                </Grid>
            </>
        )
    }

    renderDogVaccinations = () => {
        const { petDetails, classes } = this.props;
        return (

            <>
                {petDetails.pet_type === 'dog' &&
                    <>
                        {petDetails.cainine_parvovirus && <Grid item>
                            <Typography variant="body1" className={classes.heading}> Canine Parvovirus (a.k.a. Parvo) Vaccination date:
                                <Typography display="inline" variant="body1" className={classes.dailogHeading}>
                                    {petDetails.cainine_parvovirus_date && <>{moment(petDetails.cainine_parvovirus_date).format('DD/MM/YYYY')}</>}
                                </Typography>
                            </Typography>

                        </Grid>}
                        {petDetails.cainine_infectious && <Grid item>
                            <Typography variant="body1" className={classes.heading}>Canine Infectious Hepatitis Vaccination date:
                                <Typography display="inline" variant="body1" className={classes.dailogHeading}>
                                    {petDetails.cainine_infectious_date && <>{moment(petDetails.cainine_infectious_date).format('DD/MM/YYYY')}</>}
                                </Typography>
                            </Typography>

                        </Grid>}
                        {petDetails.distemper && <Grid item>
                            <Typography variant="body1" className={classes.heading}>Distemper Vaccination date:
                                <Typography display="inline" variant="body1" className={classes.dailogHeading}>
                                    {petDetails.distemper_date && <>{moment(petDetails.distemper_date).format('DD/MM/YYYY')}</>}
                                </Typography>
                            </Typography>

                        </Grid>}{petDetails.leptospirosis && <Grid item>
                            <Typography variant="body1" className={classes.heading}>Leptospirosis Vaccination date:
                                <Typography display="inline" variant="body1" className={classes.dailogHeading}>
                                    {petDetails.leptospirosis_date && <>{moment(petDetails.leptospirosis_date).format('DD/MM/YYYY')}</>}
                                </Typography>
                            </Typography>

                        </Grid>
                        }
                        {petDetails.kennel_cough && <Grid item>
                            <Typography variant="body1" className={classes.heading}>Kennel Cough Vaccination date:
                                <Typography display="inline" variant="body1" className={classes.dailogHeading}>
                                    {petDetails.kennel_cough_date && <>{moment(petDetails.kennel_cough_date).format('DD/MM/YYYY')}</>}
                                </Typography>
                            </Typography>

                        </Grid>}
                    </>
                }
            </>
        )
    }

    additionalServiceDetails = (value: AdditionalServicesforPets, daysDifference: number, dogcount1: number, catcount1: number) => {
        if (value.service_provided_type === "Daily" && value.isAllPets === "Per Pet") {
            return (
                <>
                    <div style={webStyle.perPetFlow}>
                        <Typography style={webStyle.totalCalculation}>{`£${value.service.price} ${value.service.name} for ${value.pets.length} pets X ${daysDifference} Days`}</Typography>
                        <Typography style={webStyle.totalCalculation}>{this.getPetsNames(value.pets)}</Typography>
                    </div>
                    <Typography style={webStyle.totalCalculation}>{`£${this.getServiceTotalDaily(value.service.price, value.pets.length, daysDifference)}`}</Typography>
                </>
            )
        } else if (value.service_provided_type === "OneTime" && value.isAllPets === "Per Pet") {
            return (
                <>
                    <div style={webStyle.perPetFlow}>
                        <Typography style={webStyle.totalCalculation}>{`£${value.service.price} ${value.service.name} for ${value.pets.length} pets`}</Typography>
                        <Typography style={webStyle.totalCalculation}>{this.getPetsNames(value.pets)}</Typography>
                    </div>
                    <Typography style={webStyle.totalCalculation}>{`£${this.getServiceOneTime(value.service.price, value.pets.length)}`}</Typography>
                </>
            )
        } else if (value.service_provided_type === "OneTime" && value.isAllPets === "All Pets") {
            return (
                <>
                    <Typography style={webStyle.totalCalculation}>{`£${value.service.price} ${value.service.name} for ${this.getPetCount(dogcount1, catcount1)} pets`}</Typography>
                    <Typography style={webStyle.totalCalculation}>{`£${this.getServiceOneTime(value.service.price, this.getPetCount(dogcount1, catcount1))}`}</Typography>
                </>
            )
        } else {
            return (
                <>
                    <Typography style={webStyle.totalCalculation}>{`£${value.service.price} ${value.service.name} for ${this.getPetCount(dogcount1, catcount1)} pets X ${daysDifference} Days`}</Typography>
                    <Typography style={webStyle.totalCalculation}>{`£${this.getServiceTotalDaily(value.service.price, this.getPetCount(dogcount1, catcount1), daysDifference)}`}</Typography>
                </>
            )
        }
    }

    renderClaculation = () => {
        const { reservationDetails } = this.props
        const checkInDate: any = new Date(reservationDetails.attributes.check_in_date);
        const checkOutDate: any = new Date(reservationDetails.attributes.check_out_date);

        const timeDifference = checkOutDate - checkInDate

        const daysDifference: any = timeDifference / (1000 * 60 * 60 * 24);
        const totalDays = reservationDetails?.attributes?.hotel_information?.pet_type === "daycare_services" ?
            this.getDays() :
            daysDifference;
        // this.setState({ totalcalculationdays: daysDifference })
        const selected_room_dog1: any = reservationDetails?.attributes?.for_dog?.room_type_for_dog || []
        const selected_room_cat1 = reservationDetails?.attributes.for_cat?.room_type_for_cat || []

        const dogcount1 = (reservationDetails?.attributes.number_of_dogs)
        const catcount1 = (reservationDetails?.attributes.number_of_cats)

        const selected_service_cat1: Additionalservicesforcat[] = []
        const selectedServicePets: Array<AdditionalServicesforPets> = reservationDetails?.attributes?.additional_services_for_dog || [];
        const selectedOwnServicePets: Array<AdditionalServicesforPets> = reservationDetails?.attributes?.own_services_for_dog || [];
        let totalcalculatecatroom = 0
        let totalcalculatedogroom = 0

        selected_room_dog1.forEach((value: any) => {

            totalcalculatedogroom = totalcalculatedogroom + value.price * reservationDetails.attributes.number_of_dogs * daysDifference

        })

        selected_room_cat1.forEach((value: any) => {

            totalcalculatecatroom = totalcalculatecatroom + value.price * reservationDetails.attributes.number_of_cats * daysDifference

        })




        let totalcalculatedogservice = 0
        let totalcalculatecatservice = this.calculateServicePrice(selected_service_cat1, 0, catcount1 + dogcount1);
        let finaltotalcalculation = 0




        finaltotalcalculation = totalcalculatecatroom + totalcalculatecatservice + totalcalculatedogroom + totalcalculatedogservice

        return (
            <>
                {reservationDetails.attributes.room_for_dogs.length > 0 && reservationDetails.attributes.room_for_dogs.map((value: { id: number, pets: string[], price: number, type: string }) => {

                    return (
                        <>
                            <Box style={{ width: "95%", padding: "5px 5px", marginTop: "5px", display: "flex", justifyContent: "space-between" }}>
                                <Typography style={webStyle.totalCalculation}>{`£${value.price} ${value.type} for ${value.pets.length} dog X ${daysDifference} Days`}</Typography>
                                <Typography style={webStyle.totalCalculation}>{`£${this.getPrice(value.price, daysDifference)}`}</Typography>
                            </Box>
                        </>
                    )
                })}


                {reservationDetails.attributes.room_for_cats.length > 0 && reservationDetails.attributes.room_for_cats.map((value: { id: number, pets: string[], price: number, type: string }) => {
                    return (
                        <>
                            <Box style={{ width: "95%", marginTop: "5px", display: "flex", justifyContent: "space-between", padding: "5px 5px" }}>
                                <Typography style={webStyle.totalCalculation}>{`£${value.price} ${value.type} for ${value.pets.length} cat X ${daysDifference} Days`}</Typography>
                                <Typography style={webStyle.totalCalculation}>{`£${this.getPrice(value.price, daysDifference)}`}</Typography>
                            </Box>
                        </>
                    )
                })}

                {reservationDetails.attributes.hotel_information.pet_type === "daycare_services" && <Box style={webStyle.priceBox}>
                    <Typography style={webStyle.totalCalculation}>{`£${this.state.daycarePrice} Day care service for ${reservationDetails.attributes.pets.data.length} pets ${this.getNumberOfWeeks()}`}</Typography>
                    <Typography style={webStyle.totalCalculation}>{`£${this.getDayCarePrice(this.state.daycarePrice, reservationDetails.attributes.pets.data.length)}`}</Typography>
                </Box>
                }


                {selectedServicePets !== null && selectedServicePets.map((value: AdditionalServicesforPets) => {
                    return (
                        <>
                            <Box style={{ padding: "5px 5px", marginTop: "5px", width: "95%", display: "flex", justifyContent: "space-between" }}>
                                {value && this.additionalServiceDetails(value, totalDays, dogcount1, catcount1)}
                            </Box>
                        </>
                    )
                })}

                {selectedOwnServicePets !== null && selectedOwnServicePets.map((value: AdditionalServicesforPets) => {
                    return (
                        <>
                            <Box style={{ padding: "5px 5px", marginTop: "5px", width: "95%", display: "flex", justifyContent: "space-between" }}>
                                {value && this.additionalServiceDetails(value, totalDays, dogcount1, catcount1)}
                            </Box>
                        </>
                    )
                })}

                {reservationDetails.attributes.stripe_charges !== null && <Box style={webStyle.priceBox}>
                    <Typography style={webStyle.totalCalculation} data-test-id="transactionFee">{configJSON.transactionFee}</Typography>
                    <Typography style={webStyle.totalCalculation}>£{this.props.reservationDetails.attributes.total_charges}</Typography>
                </Box>}


                <Divider style={{ backgroundColor: "#B2E4F8", margin: "10px 8px" }} />
                {this.totalAmount()}

            </>
        )

    }

    totalAmount = () => {
        const { reservationDetails, paymentDetails } = this.props
        const { paid_amount, pending_amount, stripe_charges, order_amount } = paymentDetails;

        return (
            <>
                <Box style={{ width: "95%", padding: "5px", borderRadius: "8px", display: "flex", justifyContent: "space-between" }}>
                    <Typography style={{ fontSize: "14px", fontWeight: 700 }}>Total</Typography>
                    <Typography style={webStyle.totalCalculation}>
                        {this.props.popUpBtnText === "Check-Out" ? this.getTotalFloatingValue(order_amount, "£0") :
                            this.getFloatingValue(order_amount, Number(reservationDetails.attributes.total_charges))

                        }
                    </Typography>

                </Box>
                <Box style={{ width: "95%", padding: "10px 5px", borderRadius: "8px", display: "flex", justifyContent: "space-between" }}>
                    <Typography style={{ fontSize: "14px", fontWeight: 700 }}>{this.props.popUpBtnText != "Check-Out" ? "Deposit Paid" : "Total Paid"}</Typography>
                    <Typography style={webStyle.totalCalculation}>
                        {this.props.popUpBtnText === "Check-Out" ? this.getTotalFloatingValue(paid_amount, "£0") :
                            this.getFloatingValue(paid_amount, Number(reservationDetails.attributes.stripe_charges))}
                    </Typography>

                </Box>
                <Box style={{ width: "95%", padding: "10px 5px", borderRadius: "8px", display: "flex", justifyContent: "space-between" }}>
                    <Typography style={{ fontSize: "14px", fontWeight: 700 }}>Amount Due</Typography>
                    <Typography style={webStyle.totalCalculation} data-test-id="pending_amount">{this.props.popUpBtnText === "Check-Out" ? this.getTotalFloatingValue(pending_amount, "£0") :
                        this.getTotalFloatingValue(pending_amount, stripe_charges)}

                    </Typography>

                </Box>
            </>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props
        return (
            <>
                <Dialog open={this.props.open} maxWidth={'md'}
                    aria-labelledby="form-dialog-title"
                    PaperProps={{
                        style: {
                            maxWidth: this.props.dailogType === 'checkin' ? "972px" : "669px",
                            height: 'max-content',
                            borderRadius: '8px',
                            width: '100%',

                        },
                    }}
                >
                    {this.props.dailogType !== 'checkin' && <DialogTitle className={classes.closeIconLayout}>

                        <IconButton data-test-id='closeCommonDailog' onClick={this.closeCommonDailog}>
                            <CloseIcon />

                        </IconButton>
                    </DialogTitle>}
                    <DialogContent className={this.props.dailogType === 'reject' ? classes.rejectDailog :

                        classes.dailogContent} >
                        {this.props.dailogType === 'reject' && <Grid item xs={12}>
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h4" className={classes.heading} style={{ marginTop: '15px' }}>
                                        Reject the request
                                    </Typography>

                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" gutterBottom className={classes.dailogHeading}>
                                        Reason for rejection
                                    </Typography>
                                    <CustomizedTextField
                                        value={this.state.rejectMessage}
                                        onChange={this.handleRejectMessage}
                                        className={classes.multilineInput}
                                        fullWidth
                                        variant="outlined"
                                        multiline
                                        minRows={4}
                                        data-test-id='reject_message'
                                        placeholder="Please specify the reason for rejecting the booking request here"
                                    />

                                </Grid>
                                {this.state.errorRejectMessage && <Grid item xs={12} >
                                    <Typography variant="subtitle2" color="error">{this.state.errorRejectMessage}</Typography>

                                </Grid>}

                            </Grid>

                        </Grid>}
                        {this.props.dailogType === 'settingsFilters' &&
                            <Grid item xs={12} style={{ width: '100%' }}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" className={classes.filterHeading}>{configJSON.filterPopup.heading}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" className={classes.inputLabels}>{configJSON.filterPopup.calenerInputLabel}</Typography>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box className={classes.inputDropDownBox}
                                                    onClick={this.props.openSelectDate}
                                                    data-test-id='open_calendar'

                                                >
                                                    <Box className={classes.inputDropDownBoxContent}>
                                                        <Box display={'flex'} justifyContent={'flex-start'}
                                                            alignItems={'center'}>
                                                            <img src={calenderIcon} style={{ margin: "0 10px" }} />
                                                            <Typography variant='body1' >{this.props.inputFieldDate ? this.props.inputFieldDate : "Select Date"}</Typography>
                                                        </Box>
                                                        <Box>
                                                        </Box>
                                                    </Box>
                                                    {this.props.openCalenderStatus && this.renderReviewsCalender()}
                                                </Box>
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>

                                            <Grid item xs={12}>

                                                <Typography variant="body1" className={classes.inputLabels}>{configJSON.filterPopup.petsInputLabel}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>

                                                <Box className={classes.inputDropDownBox} >
                                                    <Box className={classes.inputDropDownBoxContent}>
                                                        <Box>
                                                            <Typography variant="body1">
                                                                {this.props.numberOfPets} &nbsp;pets
                                                            </Typography>
                                                        </Box>
                                                        <Box >
                                                            <Grid item>
                                                                <Grid container direction="row" spacing={2} alignItems="center">
                                                                    <Grid item onClick={this.props.minusPets} data-test-id='minus_pets'>
                                                                        {/* <img src={minusIcon} /> */}
                                                                        <Box>
                                                                            <RemoveCircleOutlineIcon color="secondary" width={16} style={{ height: '16px' }} />

                                                                        </Box>

                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant="h6">{this.props.numberOfPets}</Typography>

                                                                    </Grid>
                                                                    <Grid item onClick={this.props.addPets} data-test-id='add_pets'>
                                                                        <AddCircleOutlineIcon color="secondary" width={16} style={{ height: '16px' }} />


                                                                    </Grid>

                                                                </Grid>

                                                            </Grid>

                                                        </Box>

                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" spacing={1} alignItems="center">
                                            <Grid item xs={12}>
                                                <Typography variant="body1" className={classes.inputLabels}>{configJSON.filterPopup.priceInputLabel}</Typography>


                                            </Grid>
                                            <Grid item xs={6}>
                                                <CustomizedTextField
                                                    variant="outlined"
                                                    fullWidth
                                                    placeholder="Min Range"
                                                    onChange={this.props.handlePriceInput}
                                                    value={this.props.price.min}
                                                    name="min"
                                                    data-test-id='min_value'
                                                />
                                            </Grid>
                                            <Grid item xs={6}>

                                                <CustomizedTextField
                                                    data-test-id='max_value'
                                                    onChange={this.props.handlePriceInput}
                                                    value={this.props.price.max}
                                                    variant="outlined"
                                                    name="max"
                                                    fullWidth
                                                    placeholder="Max Range"
                                                />
                                            </Grid>

                                        </Grid>


                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" className={classes.inputLabels}>{'Room type'}</Typography>


                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box className={classes.inputDropDownBox}
                                                    data-test-id='open_room_types'
                                                    onClick={this.props.openRoomType} >
                                                    <Box className={classes.inputDropDownBoxContent}>

                                                        <Typography variant="body1">
                                                            {this.props.selectedRoomType.length > 0 ? this.props.selectedRoomType : 'Select Room'}
                                                        </Typography>
                                                        <img src={arrowDown} />
                                                        {this.props.openRoomTypes && this.renderRoomTypes()}
                                                    </Box>
                                                </Box>
                                            </Grid>


                                        </Grid>



                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" spacing={1} >
                                            <Grid item xs={12}>
                                                <Typography variant="body1" className={classes.inputLabels}>{configJSON.filterPopup.roomsInputLabel}</Typography>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container direction="row" justifyContent="space-between" style={{ margin: 'auto' }}>
                                                    <Grid container direction="row" justifyContent="space-between" style={{ margin: 'auto' }}>
                                                        {this.renderRooms()}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>


                                    </Grid>


                                </Grid>

                            </Grid>

                        }


                        {this.props.dailogType === 'pets' &&
                            this.renderPetDetails()}
                        {this.props.dailogType == 'checkin' && <>
                            {this.renderCheckInPopUp()}
                        </>}
                    </DialogContent>
                    <DialogActions className={this.props.dailogType === 'reject' ? classes.rejectActionDailog : classes.padding15}>
                        {this.renderDailogButtons(this.props.dailogType)}
                    </DialogActions>
                </Dialog>

            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    perPetFlow: {
        display: "flex" as "flex",
        flexDirection: "column" as "column"
    },
    priceBox: {
        width: "95%",
        marginTop: "5px",
        display: "flex",
        justifyContent: "space-between",
        padding: "5px 5px"
    },
    additionalselect: {
        height: "58px",
        padding: "5px 10px",
        borderRadius: "8px",
        border: "1px solid #65C9F2",
        position: "relative",
        backgroundColor: "#E0F4FC",
        justifyContent: "space-between",
        display: "flex",
        cursor: "pointer",
        alignItems: "center",
        marginTop: "10px",
    },
    countrySpan: {
        color: "#000",
        fontSize: "14px",
        fontWeight: 700, fontFamily: "inter",
        textTransform: "capitalize"
    },
    totalCalculation: {
        fontSize: "14px",
        fontWeight: 400
    },
    noText: {
        marginTop: '20px'
    },
    layoutPadding: {
        padding: "0px 12px"
    }
}
export const ReservationDailogsStyles = (theme: Theme) =>
    createStyles({
        additionalQuestionsBox: {
            width: "100%",
            padding: "20px 3px"
        },
        additionalQuestionsText: {
            color: "#0F172A",
            fontWeight: 700,
        },
        questionText: {
            fontSize: "14px",
            fontFamily: "Inter",
            fontWeight: 700,
            lineHeight: 1.5,
        },
        answerText: {
            fontSize: "14px",
            fontFamily: "Inter",
            fontWeight: 500,
            lineHeight: 1.5,
        },
        questionBox: {
            padding: "10px 5px"
        },
        closeIconLayout: {
            padding: 10,
            display: 'flex',
            justifyContent: "flex-end",
            alignItems: 'center'
        },
        rejectBtn: {
            width: '100%',
            textTransform: 'capitalize',
            height: 56,
            borderRadius: 8,
            fontSize: 16,
            color: theme.palette.secondary.main,
            fontWeight: theme.typography.fontWeightBold,
            backgroundColor: theme.palette.common.white,

            '&:hover': {
                backgroundColor: theme.palette.common.white
            },
        },
        padding15: {
            padding: "0 20px 20px 20px"
        },
        dailogHeading: {
            margin: '0 5px',
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightBold
        },
        dailogSubHeading: {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightRegular
        },
        dailogContent: {
            overflowY: 'auto'
            // display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'center',
            // flexDirection: 'column'

        },
        rejectDailog: {
            overflowY: 'hidden',
            padding: '0 40px 0 40px'
        },
        rejectActionDailog: {
            padding: '40px'
        },
        dividerLayout: {
            flexBasis: "auto !important",
            marginBottom: 15
        },
        yesButton: {
            border: `2px solid ${theme.palette.secondary.main}`,
            textTransform: 'capitalize',
            height: 56,
            borderRadius: 8,
            fontSize: 16,
            fontWeight: theme.typography.fontWeightBold,
            '&:hover': {
                border: `2px solid ${theme.palette.secondary.main}`,

            },
            width: '100%'

        },
        heading: {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: '22px'
        },
        multilineInput: {
            "& .MuiInputBase-root": {
                height: 'auto',
            },
        },
        inputDropDownBox: {
            border: `1px solid ${theme.palette.info.main}`,
            height: 44,
            minWidth: '100%',
            borderRadius: 8,
            backgroundColor: theme.palette.primary.main,
            cursor: 'pointer',
            position: 'relative'
        },
        inputDropDownBoxContent: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '100%',
            padding: "0 10px"
        },
        countBox: {
            width: 60,
            height: 44,
            border: `1px solid ${colors.primaryBlue}`,
            borderRadius: 8,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
        },
        searchBtn: {
            width: '50%',
            textTransform: 'capitalize',
            height: 56,
            borderRadius: 8,
            fontSize: 16,
            color: theme.palette.common.white,
            fontWeight: theme.typography.fontWeightBold,
            backgroundColor: theme.palette.secondary.main,

            '&:hover': {
                backgroundColor: theme.palette.secondary.main
            },
        },
        checkBtn: {
            width: '100%',
            textTransform: 'capitalize',
            height: 56,
            borderRadius: 8,
            fontSize: 16,
            color: theme.palette.common.white,
            fontWeight: theme.typography.fontWeightBold,
            backgroundColor: theme.palette.secondary.main,

            '&:hover': {
                backgroundColor: theme.palette.secondary.main
            },
        },
        filterHeading: {
            color: colors.greyBlack,
            fontWeight: theme.typography.fontWeightBold
        },
        inputLabels: {
            color: "#000000",
            opacity: '0.5',
            fontWeight: theme.typography.fontWeightBold
        },
        titleDivider: {
            minWidth: '323px',
            maxWidth: "100%"

        },
        verticalDivider: {
            backgroundColor: theme.palette.info.main
        },
        cursor: {
            cursor: 'pointer'
        }, disbaledText: {
            color: theme.palette.text.disabled,
            fontWeight: theme.typography.fontWeightRegular
        },
        disabledDivider: {
            backgroundColor: theme.palette.text.disabled,
        },
        enableDivider: {
            backgroundColor: theme.palette.common.black,

        },
        tableSection: {
            borderRadius: 8,
            border: "1px solid #65C9F1",
            background: "#F0FAFE",
            padding: 20,
            minHeight: '190px',
            height: '100%'
        },
        HotelImage: {
            borderRadius: 8,
            width: '100%',
            maxWidth: '100%',
        },
        hotelDetails: {
            // gap: 10,
            padding: 10
        }, primaryBox: {
            boxShadow: "0px 8px 32px 0px rgba(67, 134, 161, 0.06), 0px 4px 8px 0px rgba(67, 134, 161, 0.03)"
        },
        dividerTextLayout: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },
        dividerWidth: {
            width: '100%'
        },
        customCalender: {
            fontFamily: "Inter",
            boxShadow: 'none',
            [theme.breakpoints.down('xs')]: {
                width: "-webkit-fill-available !important",
                padding: "0.4rem",
            },
            "& .rmdp-header": {
                fontSize: "18px",
                [theme.breakpoints.down('xs')]: {
                    fontSize: "14px",
                }
            },
            "& .rmdp-calendar": {
                width: "-webkit-fill-available",
                padding: "0.2rem"
            },
            "& .rmdp-day-picker": {
                [theme.breakpoints.down('xs')]: {

                    display: "block !important"
                }
            },
            "& .rmdp-week-day": {
                fontSize: "15px",
                fontWeight: 400,
                padding: '4px'
            },
            "& .rmdp-range": {
                background: "pink",
                opacity: '0.5',
                boxShadow: 'none',
                color: theme.palette.secondary.main
            },
            '& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover': {
                background: 'pink',
                color: theme.palette.common.white
            },
            "& .rmdp-day.rmdp-today span": {
                background: 'none',
                color: theme.palette.common.black
            }
        },
        claenderLayout: {
            width: '100%',
            position: 'absolute',
            top: 40,
            boxShadow: boxShadows.whiteShadow,
            zIndex: 99,
            background: theme.palette.common.white
        }, crossicon: {
            cursor: 'pointer'
        },
        outer: {
            padding: '0.5rem'
        },
        labelText: {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightBold

        },
        dropDownTextRoomType: {
            color: theme.palette.common.black,
            fontSize: 14,
            padding: '0.5rem',
            fontWeight: theme.typography.fontWeightRegular,
            '&:hover': {
                background: colors.primaryBlue,
                borderRadius: 8
            }
        },
        clearDatesText: {
            color: theme.palette.secondary.main,
            fontWeight: theme.typography.fontWeightMedium
        },
        imageFocus: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            backgroundColor: theme.palette.primary.main,
            borderRadius: 8,
            width: '100%',
        }
    })
export default withStyles(ReservationDailogsStyles)(ReservationDailog)
// Customizable Area End
